<template>
  <footer class="border-t py-3 text-center">
    <p class="text-sm">Copyright © {{ year }} Improver LLC</p>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  computed: {
    year() {
      return (new Date()).getFullYear()
    }
  }
}
</script>