<template>
  <div class="mt-5 mb-12 mx-5">
    <div class="flex flex-col items-center justify-items-center py-5">
      <h2 class="text-xl border-b-4 px-4 font-semibold">企業情報</h2>
    </div>
    <div class="mx-auto w-fit">
      <div class="flex flex-row">
        <div class="w-20">会社名：</div>
        <div>インプルーバー合同会社</div>
      </div>
      <div class="flex flex-row">
        <div class="w-20">設立日：</div>
        <div>2020年6月19日</div>
      </div>
      <div class="flex flex-row">
        <div class="w-20">代表：</div>
        <div>小島一也</div>
      </div>
      <div class="flex flex-row">
        <div class="w-20 min-w-fit">サービス：</div>
        <div>Webシステムの開発、教育コンテンツ制作</div>
      </div>
      <div class="flex flex-row">
        <div class="w-20">資本金：</div>
        <div>1,000,000円</div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>