<template>
  <PageHeader/>
  <div class="mx-auto max-w-7xl lg:x8">
    <HeroSection/>
    <div id="service">
      <WebDevelopment/>
    </div>
    <div class="border-t"></div>
    <LearningContents/>
    <div class="border-t"></div>
    <RepresentativeDirector/>
    <div class="border-t"></div>
    <div id="company">
      <CompanySection/>
    </div>
    <div class="border-t"></div>
    <div id="inquiry">
      <InquiryForm/>
    </div>
  </div>
  <PageFooter/>
</template>

<script>
import InquiryForm from '@/components/InquiryForm.vue'
import PageHeader from '@/components/PageHeader.vue'
import HeroSection from '@/components/HeroSection.vue'
import PageFooter from '@/components/PageFooter.vue'
import WebDevelopment from '@/components/WebDevelopment.vue'
import LearningContents from '@/components/LearningContents.vue'
import CompanySection from '@/components/CompanySection.vue'
import RepresentativeDirector from '@/components/RepresentativeDirector.vue'

export default {
  name: 'HomePage',
  components: {
    RepresentativeDirector,
    CompanySection,
    LearningContents,
    WebDevelopment,
    PageFooter,
    HeroSection,
    PageHeader,
    InquiryForm
  }
}
</script>

<style lang="scss" scoped>
</style>
