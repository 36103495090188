<template>
  <div class="flex">
    <div class="flex-1 backdrop-blur">
      <img class="w-full object-cover md:h-80 h-40" src="@/assets/coding.jpg" alt="コーディング画像">
      <div class="flex flex-col  items-center justify-center h-0">
        <div class="my-3 relative md:-top-40 -top-20 text-white backdrop-blur z-10 md:p-10 p-3 font-medium">
          <div class="md:text-5xl text-2xl mb-5">Web Service Development</div>
          <div class="md:text-2xl text-xl">
            最新のIT技術を用いた<br>
            高品質なWebシステムを開発致します。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeroSection'
}
</script>
